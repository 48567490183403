import React, { Component } from "react";
import classNames from "classnames";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import StatsMenu from "./components/StatsMenu/StatsMenu";
import "./App.scss";
import MapDisplay from "./components/Map/Map";
import FontIcon from "material-ui/FontIcon";
import store from "./store";
import { MENU_TOGGLE } from "./services/StatsMenu/reducer";
import { OVERLAY_TOGGLE } from "./services/Overlay/reducer";
import { MODAL_TOGGLE } from "./services/Modal/reducer";
import logo from "./assets/logo.svg";
import { GoogleApiWrapper } from "google-maps-react";
import * as _ from "lodash";
import { userType } from "./constants/userType";

import {
  CLEANUP_DATA,
  APP_DISPLAYLOGIN,
  APP_LOGGEDIN,
  APP_USER,
  APP_USERFIRST,
  APP_USERLAST,
  APP_USERTYPE,
  APP_POUNDS,
  POSITION,
} from "./services/App/reducer";
import { PIN_DATA, PENDING_DATA } from "./services/CleanUp/reducer";
import Form from "./components/Form/Form";
import { hot } from "react-hot-loader";
import { CSVLink } from "react-csv";
import firebase from "./firebase.js";
import CleanUp from "./components/CleanUp/CleanUp";
import Login from "./components/LogIn/LogIn";
import Pending from "./components/Pending/Pending";
import List from "./components/List/List";
import { DeviceBattery20 } from "material-ui/svg-icons";
import Search from "./components/Search/Search";
import { toCsv, pivot } from "./services/CSV/csv";

class App extends Component {
  constructor(props) {
    super(props);
    this.countRef = React.createRef();
    this.csvLink = React.createRef();
    this.state = {
      statsMenu: store.getState().statsMenuReducer,
      overlay: store.getState().overlayReducer,
      modal: store.getState().modalReducer,
      data: store.getState().appReducer.data,
      cleanUpWindowOpen: store.getState().cleanUpReducer.isOpen,
      pendingWindowOpen: store.getState().cleanUpReducer.pendingOpen,
      loggedIn: store.getState().appReducer.loggedIn,
      loginModal: store.getState().appReducer.displayLoginModal,
      userType: store.getState().appReducer.userType,
      pendingData: store.getState().cleanUpReducer.pendingData,
      scrollPosition: store.getState().appReducer.scrollPosition,
      csvData: null,
      query: "",
      filter: "",
      startDate: null,
      endDate: null,
    };
  }

  async componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      this.setState({
        statsMenu: store.getState().statsMenuReducer,
        overlay: store.getState().overlayReducer,
        modal: store.getState().modalReducer,
        data: store.getState().appReducer.data,
        cleanUpWindowOpen: store.getState().cleanUpReducer.isOpen,
        pendingWindowOpen: store.getState().cleanUpReducer.pendingOpen,
        loggedIn: store.getState().appReducer.loggedIn,
        loginModal: store.getState().appReducer.displayLoginModal,
        userType: store.getState().appReducer.userType,
        pendingData: store.getState().cleanUpReducer.pendingData,
        scrollPosition: store.getState().appReducer.scrollPosition,
      });
    });
    this.fireBaseAuth();
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.unsubscribeDB();
    this.unsubscribeDB1();
    this.unsubscribeDB2();
  }

  toggleMenu() {
    store.dispatch({
      type: MENU_TOGGLE,
    });
  }

  fireBaseAuth() {
    const db = firebase.firestore();
    let dataArray;
    let poundsAmount = 0;
    let pendingDataArray;
    let _this = this;
    let initLoad = false;

    firebase.auth().onAuthStateChanged(function (user) {
      dataArray = [];

      pendingDataArray = [];

      if (user) {
        store.dispatch({
          type: APP_LOGGEDIN,
          loggedIn: true,
        });

        store.dispatch({
          type: OVERLAY_TOGGLE,
          isDisplayed: false,
        });

        store.dispatch({
          type: APP_DISPLAYLOGIN,
          displayLoginModal: false,
        });

        store.dispatch({
          type: APP_USER,
          user: user.uid,
        });

        _this.unsubscribeDB1 = db
          .collection("users")
          .where("id", "==", user.uid)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              store.dispatch({
                type: APP_USERFIRST,
                userFirst: doc.data().firstName,
              });

              store.dispatch({
                type: APP_USERLAST,
                userLast: doc.data().lastName,
              });

              store.dispatch({
                type: APP_USERTYPE,
                userType: doc.data().type,
              });

              if (doc.data().type === userType.submitter) {
                return;
              } else {
                let queryRef = db.collection("cleanups");

                queryRef = queryRef.orderBy("date", "desc").limit(100);

                _this.unsubscribeDB1 = queryRef.onSnapshot(function (snapshot) {
                  snapshot.docChanges().forEach(function (change) {
                    if (change.type === "added") {
                      //console.log("Added: ", change.doc.data());
                      let object = { id: change.doc.id, ...change.doc.data() };
                      if (!initLoad) {
                        dataArray.push(object);
                      } else {
                        dataArray.unshift(object);
                      }
                    }
                    if (change.type === "modified") {
                      console.log("Modified: ", change.doc.data());

                      if (change.doc.data().status === 2) {
                        let index = _.findIndex(pendingDataArray, function (o) {
                          return o.id === change.doc.id;
                        });
                        pendingDataArray.splice(index, 1);
                        pendingDataArray.unshift({
                          id: change.doc.id,
                          ...change.doc.data(),
                        });
                        store.dispatch({
                          type: PENDING_DATA,
                          pending: pendingDataArray,
                        });
                      } else {
                        let index = _.findIndex(dataArray, function (o) {
                          return o.id === change.doc.id;
                        });
                        dataArray.splice(index, 1);
                        dataArray.unshift({
                          id: change.doc.id,
                          ...change.doc.data(),
                        });
                        store.dispatch({
                          type: PIN_DATA,
                          pinData: { id: change.doc.id, ...change.doc.data() },
                        });
                      }
                    }
                    if (change.type === "removed") {
                      // console.log("Removed: ", change.doc.data());
                    }
                  });

                  initLoad = true;

                  store.dispatch({
                    type: CLEANUP_DATA,
                    data: dataArray,
                  });
                });

                _this.unsubscribeDB2 = db
                  .collection("cleanups")
                  .onSnapshot(function (snapshot) {
                    snapshot.docChanges().forEach(function (change) {
                      let data = change.doc.data();

                      if (data.status === 1 || data.status === undefined) {
                        poundsAmount += parseInt(data.poundsPulled);
                      }
                    });

                    store.dispatch({
                      type: APP_POUNDS,
                      pounds: poundsAmount,
                    });
                  });

                _this.unsubscribeDB1 = db
                  .collection("cleanups")
                  .where("status", "==", 2)
                  .orderBy("date", "desc")
                  .get()
                  .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                      pendingDataArray.push({ id: doc.id, ...doc.data() });
                    });
                    if (_.size(pendingDataArray) > 0) {
                      store.dispatch({
                        type: PENDING_DATA,
                        pending: pendingDataArray,
                      });
                    } else {
                      store.dispatch({
                        type: CLEANUP_PENDING_TOGGLE,
                        pendingOpen: false,
                      });
                    }
                  })
                  .catch(function (error) {
                    console.log("Error getting documents: ", error);
                  });
              }
            });
          });
      } else {
        store.dispatch({
          type: APP_DISPLAYLOGIN,
          displayLoginModal: true,
        });

        store.dispatch({
          type: APP_LOGGEDIN,
          loggedIn: false,
        });

        store.dispatch({
          type: CLEANUP_DATA,
          data: dataArray,
        });

        store.dispatch({
          type: PENDING_DATA,
          pending: pendingDataArray,
        });

        store.dispatch({
          type: OVERLAY_TOGGLE,
          isDisplayed: true,
        });
      }
    });
  }

  onOverlayClick() {
    if (this.state.statsMenu.isOpen) {
      store.dispatch({
        type: MENU_TOGGLE,
      });
    } else if (!this.state.loginModal) {
      store.dispatch({
        type: OVERLAY_TOGGLE,
        isDisplayed: false,
      });

      store.dispatch({
        type: MODAL_TOGGLE,
      });
    }
  }

  toggleModal() {
    store.dispatch({
      type: MODAL_TOGGLE,
    });

    store.dispatch({
      type: OVERLAY_TOGGLE,
      isDisplayed: !this.state.modal.isDisplayed,
    });
  }

  getQueryRef() {
    const db = firebase.firestore();
    let queryRef = db.collection("cleanups");
    const filter = this.state.filter;

    if (
      !(
        !filter ||
        (!this.state.query && filter != "date") ||
        ((!this.state.startDate || !this.state.endDate) && filter == "date")
      )
    ) {
      if (this.state.filter == "date") {
        queryRef = queryRef
          .where("date", ">=", this.state.startDate)
          .where("date", "<=", this.state.endDate);
      }
    }
    return queryRef;
  }

  nextPage(last, position) {
    let nextArray = store.getState().appReducer.data;

    let queryRef = this.getQueryRef()
      .orderBy("date", "desc")
      .limit(100)
      .startAfter(last["date"]);

    queryRef.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        nextArray.push({ id: doc.id, ...doc.data() });
      });
      store.dispatch({
        type: POSITION,
        position: position,
      });
      store.dispatch({
        type: CLEANUP_DATA,
        data: nextArray,
      });
    });
  }

  onExportClick() {
    this.csvLink.current.link.click();
  }

  getFilteredCleanUps() {
    let _this = this;

    let dataArray = [];

    let queryRef = this.getQueryRef();
    if (_this.state.filter) {
      queryRef = queryRef.orderBy(this.state.filter);
    }
    queryRef = queryRef.limit(100);

    const filter = _this.state.filter;

    queryRef
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          let docx = { ...doc.data() };
          if (filter && filter != "date") {
            const value = docx[filter];
            if (value) {
              if (filter == "participants" || filter == "poundsPulled") {
                value == _this.state.query
                  ? dataArray.push({ id: doc.id, ...doc.data() })
                  : "";
              } else {
                if (
                  docx[filter]
                    .toLowerCase()
                    .includes(_this.state.query.toLowerCase())
                ) {
                  dataArray.push({ id: doc.id, ...doc.data() });
                }
              }
            }
          } else {
            dataArray.push({ id: doc.id, ...doc.data() });
          }
        });

        store.dispatch({
          type: CLEANUP_DATA,
          data: dataArray,
        });
      })
      .catch((err) => console.log(err));
  }

  render() {
    return (
      <MuiThemeProvider>
        <div className={"App"}>
          <div className={"App__menu"}>
            <FontIcon
              className="material-icons App__menu-icon"
              color="white"
              onClick={this.toggleMenu}
            >
              {" "}
              menu{" "}
            </FontIcon>
            <img className={" App__menu-logo"} src={logo} />
          </div>
          <div
            className={classNames(
              "App__overlay",
              { menuOpen: this.state.statsMenu.isOpen },
              { modalOpen: this.state.modal.isDisplayed },
              { loggedOut: !this.state.loggedIn }
            )}
            onClick={() => this.onOverlayClick()}
          />
          <div className="App__container">
            <StatsMenu />
            <div className="App__container__vertical__align Mobile_view">
              <div className="topHeader">
                <Search
                  filter={this.state.filter}
                  query={this.state.query}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  setHandler={(name, value) => {
                    this.setState({ ...this.state, [name]: value }, () => {
                      this.getFilteredCleanUps();
                    });
                  }}
                  setFilter={(event) => {
                    this.setState({
                      ...this.state,
                      filter: event.target.value,
                      query: "",
                    });
                  }}
                  clearFilters={() => {
                    this.setState(
                      {
                        ...this.state,
                        filter: "",
                        query: "",
                        startDate: "",
                        endDate: "",
                      },
                      () => {
                        this.getFilteredCleanUps();
                      }
                    );
                  }}
                />
                <button
                  className="exportButton"
                  onClick={() => this.onExportClick()}
                >
                  Export
                </button>
                <CSVLink
                  data={this.state.data}
                  filename="trash_tracker.csv"
                  className="hidden"
                  ref={this.csvLink}
                  target="_blank"
                />
              </div>

              <List
                data={this.state.data}
                nextPage={(last, scrollTop) => this.nextPage(last, scrollTop)}
              />
            </div>
            {this.state.cleanUpWindowOpen && (
              <CleanUp
                className={classNames({
                  noshow: !this.state.cleanUpWindowOpen,
                })}
              />
            )}
            {this.state.pendingWindowOpen && (
              <Pending
                className={classNames({
                  noshow: !this.state.pendingWindowOpen,
                })}
              />
            )}
          </div>
          {this.state.modal.isDisplayed && (
            <Form close={() => this.toggleModal()} />
          )}
          <Login />
        </div>
      </MuiThemeProvider>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAzM57qmMxJmJUak7Gqo44bdJWFS0cirEY",
})(hot(module)(App));
